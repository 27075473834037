// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-adventures-index-js": () => import("./../../../src/pages/adventures/index.js" /* webpackChunkName: "component---src-pages-adventures-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-style-guide-index-js": () => import("./../../../src/pages/style-guide/index.js" /* webpackChunkName: "component---src-pages-style-guide-index-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-restricted-js": () => import("./../../../src/pages/work/restricted.js" /* webpackChunkName: "component---src-pages-work-restricted-js" */),
  "component---src-pages-writing-index-js": () => import("./../../../src/pages/writing/index.js" /* webpackChunkName: "component---src-pages-writing-index-js" */),
  "component---src-templates-adventure-template-jsx": () => import("./../../../src/templates/adventure/template.jsx" /* webpackChunkName: "component---src-templates-adventure-template-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../../../src/templates/page/template.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-templates-product-template-jsx": () => import("./../../../src/templates/product/template.jsx" /* webpackChunkName: "component---src-templates-product-template-jsx" */),
  "component---src-templates-written-template-jsx": () => import("./../../../src/templates/written/template.jsx" /* webpackChunkName: "component---src-templates-written-template-jsx" */)
}

